import {
    getForcedControlGroup,
    getForcedTest1Group,
    getForcedTest2Group,
    getForcedTest3Group,
    getForcedTestGroup,
} from "@/utils/settings";

export enum ExperimentIds {
    // do not remove this is permanent exp for splitting users into segments
    SEGMENTS = "segments",
    AI_PERSONAL_COACH = "webtest_ai_personal_coach_FAEX_594_jan25",
    AFTER_LOADER = "webtest_after_loader_jan25",
    PAYWALL = "webtest_paywall_jan25",
    SENIORS_FUNNEL = "webtest_seniors_funnel_FAEX_573_jan25",
    YEAR_PLAN = "webtest_year_plan_FAEX_616_jan25",
}

export const enum ExperimentVariants {
    CONTROL = "control",
    TEST = "test",
    TEST_1 = "test_1",
    TEST_2 = "test_2",
    TEST_3 = "test_3",
    NOT_ALLOCATED = "not_allocated",
    SEGMENT_A = "segment_A",
    SEGMENT_B = "segment_B",
}

export const TEST_VARIANTS = [
    ExperimentVariants.TEST,
    ExperimentVariants.TEST_1,
    ExperimentVariants.TEST_2,
    ExperimentVariants.TEST_3,
];

export const groupByForcedParams: Record<ExperimentVariants, string[]> = {
    [ExperimentVariants.CONTROL]: getForcedControlGroup(),
    [ExperimentVariants.TEST]: getForcedTestGroup(),
    [ExperimentVariants.TEST_1]: getForcedTest1Group(),
    [ExperimentVariants.TEST_2]: getForcedTest2Group(),
    [ExperimentVariants.TEST_3]: getForcedTest3Group(),
    [ExperimentVariants.NOT_ALLOCATED]: [],
    [ExperimentVariants.SEGMENT_A]: [],
    [ExperimentVariants.SEGMENT_B]: [],
};

// need to track paywall id for analytics
export const paywallExperimentsIds = [ExperimentIds.YEAR_PLAN];
